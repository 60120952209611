<div *ngIf="screenSize < tablet_smartp_breakpoint" class="titlebarMT d-flex align-items-center gap-1">
  <button class="" type="button" (click)="openBottomMenu()"><mat-icon>menu_burger</mat-icon></button>
  <div class="title-bar-title">Menu</div>
</div>
<mat-toolbar class="top-bar py-1" id="main-menu"
  [ngClass]="{'otherMenu': LevelsSvc.levelOpened != null, 'd-none': !isBottomMenuOpen}">
  <mat-toolbar-row class="d-flex align-items-center justify-content-between"
    [ngClass]="{'flex-column gap-3': screenSize < tablet_smartp_breakpoint}">
    <div class="top-bar-left">
      <div id="menus-container" class="d-flex align-items-center"
        [ngClass]="{'flex-column gap-1': screenSize < tablet_smartp_breakpoint}">
        <img class="logo" src="{{strlogo}}" />
        <div>
          <a class="bold" id="user_tag"><img class="avatar" src={{UsersSvc.avatar}}><span
              class="text-white">{{UsersSvc.name}}</span></a>
        </div>

        <div *ngIf="LevelsSvc.levelOpened == null" id="menucateg" class="d-flex align-items-center"
          [ngClass]="{'flex-column': screenSize < tablet_smartp_breakpoint}">
          <div (mouseenter)="isSubmenuOpen[i] = true" (mouseleave)="isSubmenuOpen[i] = false"
            *ngFor="let cat of CategoriesSvc.categs; index as i" #allTheseThings
            [ngClass]="{'borderSolid viewport-width d-flex justify-content-center': screenSize < tablet_smartp_breakpoint}">
            @if(!cat.caddress){
            <a class="d-flex align-items-center">{{cat.cname}}<mat-icon>keyboard_arrow_down</mat-icon></a>
            <div *ngIf="isSubmenuOpen[i]" class="menu-pages">
              @for (page of cat.pages; track $index) {
              <a class="page py-2 m-0"
                [routerLink]="page.addr.queryParams['type'] ? [page.addr.path, page.addr.queryParams['type']] : [page.addr.path]">{{page.pname}}</a>
              }
            </div>
            }@else {
            <a class="d-flex align-items-center" (click)="redirectCat(cat)">{{cat.cname}}</a>
            }
          </div>
        </div>

        <!--menu per gli altri livelli-->
        <div id="other-menu-container" class="px-2"
          *ngIf="LevelsSvc.levelOpened != null && CategoriesSvc.categsOtherMenu.length > 0">
          @for (cat of CategoriesSvc.categsOtherMenu; track $index) {
          <ul class="othermenu">
            @if(!cat.caddress){
            <li #allTheseThings *ngIf="cat.pages.length != 0">
              <span
                class="catname d-flex align-items-center">{{cat.cname}}<mat-icon>keyboard_arrow_down</mat-icon></span>
              <ul class="drop">
                <div class="mt-2">
                  @for (page of cat.pages; track $index) {
                  <li class="other-menu-page py-2">
                    <a [href]="redirectPage(page)" target="_blank" (click)="LevelsSvc.resetMenu()">{{page.pname}}</a>
                  </li>
                  }
                </div>
              </ul>
            </li>
            }@else{
            <li>
              <span class="catname d-flex align-items-center"
                (click)="redirectCat(cat); LevelsSvc.resetMenu()">{{cat.cname}}</span>
            </li>
            }
          </ul>
          }
        </div>
      </div>
    </div>
    <div class="top-bar-right">
      <ul class="menu d-flex align-items-center flex-end mb-0" role="menubar">
        <li class="d-flex flex-nowrap">
          <div *ngIf="searchBar" class="dropdown-pane searchFromMenu noPadding px-3 mw-100" style="border-radius: 5px;">
            <input type="text" #inputSearch class="noMargin" name="searchField"
              style="background-color:#fff; height: 2rem;" [(ngModel)]="searchField" (keyup)="startSearch($event)" />
          </div>
          <a (click)="handleSearchBar()" class="noMargin px-1">
            <img src="{{imgSearch}}" />
          </a>
        </li>
        <li class="d-flex">
          <a routerLink="/home" class="noMargin px-1">
            <img src="{{imgHome}}" />
          </a>
        </li>
        <li class="d-flex">
          <a (click)="redirectHelp()" class="noMargin px-1" style="position: relative;">
            <img src="{{imgHelp}}" />
            <span class="badgeHelp" *ngIf="helpSvc.countHelper > 0">{{helpSvc.countHelper}}</span>
          </a>
        </li>
        <li role="menuitem" tabindex="0" class="d-flex">
          <a class="noMargin px-1" (click)="exiting()">
            <img src="{{imgLogOut}}" />
          </a>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>