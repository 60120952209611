import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { InitFunction } from 'csoft-library/init';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import {
  TranslationsService,
  UserService,
  GlobalErrorHandler,
} from 'csoft-library/servizi';
import { environment } from '../environments/environment';
import { CsoftLibraryModule } from 'csoft-library/csoft-module';
import { LayoutComponent } from 'csoft-library/componenti/layout/layout.component';
import { HomeComponent } from './home/home.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from 'csoft-library/interceptors';

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    LayoutComponent,
    CsoftLibraryModule.forRoot(environment),
    BrowserAnimationsModule,
    AppRoutingModule,
    LoadingBarHttpClientModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: InitFunction,
      deps: [TranslationsService, UserService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    UserService,
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
})
export class AppModule {}
