import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  UserService,
  MasterService,
  CategorieService,
  LivelliService,
  HelpService,
  AuthService,
} from 'csoft-library/servizi';
import { Environment } from 'csoft-library/modelli';
import { FormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-top-menu-categories',
  templateUrl: './top-menu-categories.component.html',
  styleUrls: ['./top-menu-categories.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatToolbarModule,
    RouterLink,
  ],
})
export class TopMenuCategoriesComponent implements OnInit, AfterViewInit {
  @ViewChildren('allTheseThings') things: any;
  type =
    this.environment.domain?.includes('csoftbeta') ||
    this.environment.domain?.includes('gestioneconsorzi')
      ? '_beta'
      : '';
  strlogo =
    this.environment.MediaDomain + 'images/logos/carview' + this.type + '.png';
  imgSearch = this.environment.MediaDomain + 'images/img/blue/search_blue.png';
  imgHome = this.environment.MediaDomain + 'images/img/blue/home_blue.png';
  imgHelp = this.environment.MediaDomain + 'images/img/blue/help_blue.png';
  imgLogOut =
    this.environment.MediaDomain + 'images/img/blue/logout_bluered.png';
  searchDropdown: any = null;
  searchField: string = '';
  screenSize: number;
  isBottomMenuOpen: boolean = true;
  isSubmenuOpen: boolean[] = [];
  searchBar: boolean = false;
  tablet_smartp_breakpoint: number = 1024;
  categorieList: any[] = [];
  otherCategorieList: any[] = [];
  @ViewChild('inputSearch') inputSearch: ElementRef;

  constructor(
    public UsersSvc: UserService,
    public masterCtrl: MasterService,
    public CategoriesSvc: CategorieService,
    public LevelsSvc: LivelliService,
    public helpSvc: HelpService,
    @Inject('env') public environment: Environment,
    public AuthSvc: AuthService
  ) {}

  startSearch(event: any) {
    if (event.keyCode == 13) {
      if (this.environment.currentLevel.includes('localhost')) {
        window.open(
          `${this.environment.AmministrazioneDomain}anagrafiche/search/${this.searchField}`,

          this.environment.AmministrazioneDomain.includes(
            this.environment.currentLevel
          )
            ? '_self'
            : '_blank'
        );
      } else {
        window.open(
          `${this.environment.AmministrazioneDomain}Anagrafiche/Search?value=${this.searchField}`,

          this.environment.AmministrazioneDomain.includes(
            this.environment.currentLevel
          )
            ? '_self'
            : '_blank'
        );
      }
      this.searchField = '';
    }
  }

  exiting() {
    this.AuthSvc.LogOutUser();
  }

  ngOnInit(): void {
    this.CategoriesSvc.getMenu(null);
    this.screenSize = window.innerWidth;
    this.isBottomMenuOpen =
      this.screenSize < this.tablet_smartp_breakpoint ? false : true;
  }

  ngAfterViewInit(): void {
    this.things.changes.subscribe(() => {
      setTimeout(() => {
        this.masterCtrl.showloading = false;
      });
    });
  }

  handleSearchBar() {
    this.searchBar = !this.searchBar;
    if (this.searchBar) {
      setTimeout(() => {
        this.inputSearch = this.inputSearch.nativeElement.focus();
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenSize = event.target.innerWidth;
    this.isBottomMenuOpen =
      this.screenSize < this.tablet_smartp_breakpoint ? false : true;
  }

  openBottomMenu(): void {
    this.isBottomMenuOpen = !this.isBottomMenuOpen;
  }

  redirectHelp() {
    window.open(
      this.environment.AmministrazioneDomain + 'Main/Help#/Help/List'
    );
    this.searchField = '';
  }

  redirectPage(page: any) {
    let path = '';
    if (Object.keys(page.addr.queryParams).length !== 0) {
      path = `${page.addr.path}?${Object.keys(
        page.addr.queryParams
      )}=${Object.values(page.addr.queryParams)}`;
    } else {
      path = page.addr.path;
    }

    return (
      this.environment.protocol + '//' + this.CategoriesSvc.levAddress + path
    );
  }

  redirectCat(cat: any) {
    let path = '';
    const address = cat.address || cat.caddress;

    if (Object.keys(address.queryParams).length !== 0) {
      path = `${address.path}?${Object.keys(
        address.queryParams
      )}=${Object.values(address.queryParams)}`;
    } else {
      path = address.path;
    }

    window.open(
      this.environment.protocol + '//' + this.CategoriesSvc.levAddress + path,
      '_blank'
    );
  }

  test(page: any) {
    console.log(this.LevelsSvc.levels);
    console.log(this.CategoriesSvc.categs);
    console.log(page);
  }
}
