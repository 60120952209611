<div *ngIf="screenSize && screenSize < tablet_smartp_breakpoint"
  class="title-bar titlebarMT d-flex align-items-center gap-1">
  <button (click)="openTopMenu()"><mat-icon>menu_burger</mat-icon></button>
  <div class="title-bar-title topbarMT">Aree Carview</div>
</div>
<mat-toolbar id="menuarea" class="row sticky" [ngClass]="{'d-none': !isTopMenuOpen}"
  *ngIf="UsersSvc.User && TransSvc.isLoaded">
  <mat-toolbar-row class="top-bar justify-content-center" id="top-menu" *ngIf="!LevelsSvc.hasOnlyOneLevel()"
    style="padding-bottom: 0;">
    <ul class="levels noMarginBottom flex-wrap">
      <li *ngFor="let lev of LevelsSvc.levels; index as i" class="d-flex align-items-center" [ngClass]=" {'openLevel': LevelsSvc.levelOpened==lev.ThirdLevelId,'selLevel' :
        lev.ThirdLevelId==LevelsSvc.levelOrigin, 'unselLevel' : lev.ThirdLevelId !=LevelsSvc.levelOrigin}">
        <a href="{{LevelsSvc.getLevelUrl(lev)}}" *ngIf="lev.ThirdLevelId != LevelsSvc.levelOrigin" target="_blank"
          (click)="LevelsSvc.resetMenu()">{{lev.Name}}</a>
        <a *ngIf="lev.ThirdLevelId == LevelsSvc.levelOrigin" (click)="LevelsSvc.resetMenu()">{{lev.Name}}</a>
        <img src="{{lev.ThirdLevelId == LevelsSvc.levelOpened ? otherMenuCloseIcon : otherMenuOpenIcon}}"
          *ngIf="lev.ThirdLevelId != LevelsSvc.levelOrigin" class="otherMenuOpenIcon marginLeft_small"
          (click)="LevelsSvc.openOtherLevelMenu(lev, lev.ThirdLevelId == LevelsSvc.levelOpened)">
      </li>
    </ul>
  </mat-toolbar-row>
</mat-toolbar>
<app-top-menu-categories *ngIf="UsersSvc.User && TransSvc.isLoaded"></app-top-menu-categories>
<div id="logintop" class="loginlogo" *ngIf="!UsersSvc.User">
  <div class="col-12 text-center">
    <img src="{{strlogoCarview}}" class="loginlogo" />
  </div>
</div>