import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { HeaderService } from '../header.service';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  totalNews: any[] = [];
  countHelper = 2;
  constructor(private http: HttpClient, private Header: HeaderService) {}

  GetNews() {
    const to = DateTime.local();
    const from = DateTime.local().minus({ months: 3 });
    //const url = `${environment.ApiDomain}Umbraco/Api/Messages/Get?From=${from}&To=${to}&Title=`
    const url = `http://www.csoftonline.it/Umbraco/Api/Messages/Get?From=${from}&To=${to}&Title=`;
    //console.log('Header', Header.getHeader())
    return this.http.get(url, { headers: this.Header.getHeader() });
  }

  GetCategories() {
    //const url = `${environment.ApiDomain}Umbraco/Api/Messages/GetCats`
    const url = `http://www.csoftonline.it/Umbraco/Api/Messages/GetCats`;
    //console.log('Header', Header.getHeader())
    return this.http.get(url, { headers: this.Header.getHeader() });
  }

  GetTypos() {
    //const url = `${environment.ApiDomain}Umbraco/Api/Messages/GetTypos`
    const url = `https://www.csoftonline.it/Umbraco/Api/Messages/GetTypos`;
    //console.log('Header', Header.getHeader())
    return this.http.get(url, { headers: this.Header.getHeader() });
  }
}
