import {
  Component,
  ViewChild,
  OnInit,
  AfterViewInit,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {
  UserService,
  CookieService,
  LivelliService,
  MasterService,
  TranslationsService,
  HeaderService,
  AuthService,
} from 'csoft-library/servizi';
import { Environment } from 'csoft-library/modelli';
import { CommonModule } from '@angular/common';
import {
  catchError,
  from,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [FormsModule, CommonModule],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('logInform') logInform: NgForm;
  btnEnabled = true;
  showError = false;
  error = '';
  labelButton = '';
  destroy$ = new Subject<void>();

  constructor(
    private AuthSvc: AuthService,
    private UserSvc: UserService,
    public masterCtrl: MasterService,
    private router: Router,
    private LevelsSvc: LivelliService,
    public TransSvc: TranslationsService,
    @Inject('env') private environment: Environment
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.masterCtrl.showloading = false;

    this.UserSvc.User = null;
  }

  onLogIn(form: NgForm) {
    this.labelButton = this.TransSvc.TRANSL.onlogin;
    this.btnEnabled = false;
    this.showError = false;
    this.AuthSvc.Login(form.value.username, form.value.password)
      .pipe(
        switchMap(
          () => from(this.UserSvc.getCurrentUser()) // Converts the promise to an Observable
        ),
        catchError((err) => throwError(() => err))
      )
      .subscribe({
        next: () => {
          this.LevelsSvc.GetThirdLevels();

          this.masterCtrl.showloading = true;

          this.masterCtrl.Init();

          this.router.navigate(['/home']);

          this.btnEnabled = true;

          this.labelButton = this.TransSvc.TRANSL.login;
        },
        error: (e: any) => {
          if (e.status == 401) {
            this.error = this.TransSvc.TRANSL['user_pwd_incorr']; //'Il nome utente o la password fornita non è corretta.'
            this.showError = true;
          }
          this.btnEnabled = true;
          this.labelButton = this.TransSvc.TRANSL.login;
        },
      });
  }

  presslogin(event: any) {
    if (event.keyCode == 13 && this.logInform.valid) {
      this.onLogIn(this.logInform);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.masterCtrl.showloading = false;
    }, 0);
  }

  postCrossDomainMessage(user: any) {
    const element: HTMLIFrameElement = document.getElementById(
      'ifr'
    ) as HTMLIFrameElement;
    const iframe = element.contentWindow;
    //var win = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
    // win.postMessage(user, "http://manager2.gestioneconsorzi.it");
    if (iframe !== null)
      iframe.postMessage(
        {
          action: 'save',
          key: 'user_prova',
          value: user,
        },
        this.environment.protocol + '//' + this.environment.domain
      );
  }
}
