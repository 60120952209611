import { Component } from '@angular/core';
import { MasterService, UserService } from 'csoft-library/servizi';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'amministrazione-csoftonline-it';

  constructor(public masterCtrl: MasterService, private UserSvc: UserService) {}

  ngOnInit(): void {
    this.masterCtrl.Init();
  }

  renderViews() {
    if (!environment.currentLevel.includes('localhost'))
      return !this.masterCtrl.showloading && this.UserSvc.User;
    else return !this.masterCtrl.showloading;
  }
}
