import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
  MasterService,
  UserService,
  CategorieService,
  LivelliService,
  TranslationsService,
} from 'csoft-library/servizi';
import { Environment } from 'csoft-library/modelli';
import { FormsModule } from '@angular/forms';
import { TopMenuCategoriesComponent } from 'csoft-library/componenti/top-menu-categories';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  standalone: true,
  providers: [
    {
      provide: FormsModule,
    },
  ],
  imports: [
    TopMenuCategoriesComponent,
    CommonModule,
    FormsModule,
    MatIconModule,
    MatToolbarModule,
  ],
})
export class TopMenuComponent implements OnInit {
  otherMenuOpenIcon =
    this.environment.MediaDomain + 'images/img/menu_512_grey.png';
  otherMenuCloseIcon =
    this.environment.MediaDomain + 'images/img/close_512_otherblue.png';
  type =
    this.environment.domain?.includes('csoftbeta') ||
    this.environment.domain?.includes('gestioneconsorzi')
      ? '_beta'
      : '';
  strlogoCarview =
    this.environment.MediaDomain + 'images/logos/carview' + this.type + '.jpg';
  screenSize: number;
  isTopMenuOpen: boolean = true;
  tablet_smartp_breakpoint: number = 1024;

  constructor(
    public masterCtrl: MasterService,
    public UsersSvc: UserService,
    public CategoriesSvc: CategorieService,
    public LevelsSvc: LivelliService,
    public TransSvc: TranslationsService,
    @Inject('env') private environment: Environment
  ) {}

  ngOnInit(): void {
    if (!this.masterCtrl.isAr && !this.masterCtrl.isDg && this.UsersSvc.User) {
      this.LevelsSvc.GetThirdLevels();
    } else {
      this.LevelsSvc.levelsCount = 1;
    }
    this.screenSize = window.innerWidth;
    this.isTopMenuOpen =
      this.screenSize < this.tablet_smartp_breakpoint ? false : true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenSize = event.target.innerWidth;
    this.isTopMenuOpen =
      this.screenSize < this.tablet_smartp_breakpoint ? false : true;
  }

  openTopMenu(): void {
    this.isTopMenuOpen = !this.isTopMenuOpen;
  }
}
