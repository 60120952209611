import { TranslationsService, UserService } from 'csoft-library/servizi';
import { firstValueFrom } from 'rxjs';

export function InitFunction(
  transSVC: TranslationsService,
  userSVC: UserService
) {
  return () =>
    new Promise(async (resolve, reject) => {
      try {
        // Come prima cosa recupero translation e validation
        await transSVC.Init();

        // Provo a recuperare user
        await userSVC.getCurrentUser();

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
}
