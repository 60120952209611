import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { UserService } from './user.service';
import { Environment } from 'csoft-library/modelli';
import { HeaderService } from '../header.service';
import { Subject, map, takeUntil, tap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CategorieService implements OnDestroy {
  destroy$ = new Subject<void>();
  loadedCurrLevelMenu = true;
  categs: any[] = [];
  categsOtherMenu: any[] = [];
  otherMenus: any[] = [];
  menuPagesList: any[] = [];
  reclick = false;
  levAddress = '';

  constructor(
    private http: HttpClient,
    private UsersSvc: UserService,
    @Inject('env') private environment: Environment,
    private Header: HeaderService,
    private AuthSvc: AuthService
  ) {}

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  GetCategoriesForUser(domain: string) {
    const url = `${this.environment.ApiDomain}api/Cat/GetCategoriesForMenu?username=${this.UsersSvc.User.UserName}&domain=${domain}`;
    return this.http.get(url, { headers: this.Header.getHeader() });
  }

  getMenu(level: any) {
    if (this.environment.domain.includes('carview')) return;
    this.levAddress = !level ? this.environment.domain : level.Address;
    this.loadedCurrLevelMenu = !level ? true : false;
    var cats_ = this.otherMenus.filter((l) => {
      return level && l.id === level.ThirdLevelId;
    });
    if (cats_.length > 0) {
      this.categsOtherMenu = cats_[0].cats;
    } else {
      this.GetCategoriesForUser(this.levAddress)
        .pipe(
          takeUntil(this.destroy$),

          //SEPARO PARAMS DA URL PER MANTENERE UNO STANDARD PER IL ROUTING SUL NUOVO CARVIEW
          tap((result: any) => {
            let [path, queryParamsString] = ['', ''];
            result.forEach((el: any) => {
              [path, queryParamsString] = el.Address
                ? el.Address.split('?')
                : el.CAddress.split('?');

              const queryParams: any = {};

              if (queryParamsString) {
                const params = queryParamsString.split('&');

                params.forEach((param: any) => {
                  const [key, value] = param.split('=');
                  queryParams[key] = value;
                });
              }

              if (el.Address) el.Address = { path, queryParams };
              else el.CAddress = { path, queryParams };
            });
          })
        )
        .subscribe({
          next: (result: any) => {
            if (result.Message) {
              //Da finire
            } else {
              var currCat = '';
              var currObj: any;
              var cats: any[] = [];
              for (var i = 0; i < result.length; i++) {
                if (currCat != result[i].CName) {
                  currObj = {
                    cname: result[i].CName,
                    cid: result[i].CId,
                    pages: [],
                    address: result[i]?.Address,
                    caddress: result[i]?.CAddress,
                  };
                  currObj.pages.push({
                    pid: result[i].PId,
                    pname: result[i].PName,
                    // addr: this.environment.protocol + levAddress + result[i].Address,
                    addr: result[i]?.Address,
                    show: result[i].CondShow,
                  });
                  this.menuPagesList.push(result[i]);
                  cats.push(currObj);
                  currCat = result[i].CName;
                } else {
                  currObj.pages.push({
                    pid: result[i].PId,
                    pname: result[i].PName,
                    // addr: this.environment.protocol + levAddress + result[i].Address,
                    addr: result[i]?.Address,
                    show: result[i].CondShow,
                  });
                  this.menuPagesList.push(result[i]);
                }
              }
              //nel caso in cui sono già loggato e sono entrato direttamente digitando un url diverso dalla home, verifico se sono abilitato per quella pagina
              let url = location.pathname;
              if (url != '' && url != '/home' && url != '/help') {
                let page = this.menuPagesList.filter(
                  (p) =>
                    p.Address?.path === url ||
                    url.startsWith(p.Address?.path) ||
                    p.CAddress?.path === url ||
                    url.startsWith(p.CAddress?.path)
                );
                if (!page || page.length == 0) {
                  this.AuthSvc.LogOutUser();
                }
              }
              if (cats && cats.length > 0) {
                setTimeout(() => {
                  if (level) {
                    this.categsOtherMenu = cats;
                  } else {
                    this.categs = cats;
                  }
                }, 0);
              } else {
                this.AuthSvc.LogOutUser();
              }
            }
          },
          error: (e) => {
            console.log(e);
          },
        });
    }
  }

  //PER STANDARDIZZAZRE FORMATO QUERYPARAMS PER NUOVO CARVIEW (es. /..?type=new ====> /../:type)
  // patchNewQueryParams(address: any) {}

  getCurrPageInfo() {
    for (var i = 0; i < this.menuPagesList.length; i++) {
      if (
        location.href
          .toLowerCase()
          .indexOf(
            (this.menuPagesList[i].CAddress
              ? this.menuPagesList[i].CAddress
              : this.menuPagesList[i].Address
            ).toLowerCase()
          ) >= 0
      ) {
        //$rootScope.$emit('currPageIdEv', this.menuPagesList[i].PId);
        return;
      }
    }
    if (this.loadedCurrLevelMenu) {
      //in questo caso so che si tratta della homepage del livello in cui attualmente mi trovo (diversamente vorrebbe dire che ho caricato il menu di uno degli altri livelli e non devo fare niente)
      //$rootScope.$emit('currPageIdEv', 0);
    }
  }
}
