import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Environment } from 'csoft-library/modelli';

@NgModule({ imports: [CommonModule, RouterModule, FormsModule], providers: [{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' }, provideHttpClient(withInterceptorsFromDi())] })
export class CsoftLibraryModule {
  public static forRoot(
    environment: Environment
  ): ModuleWithProviders<CsoftLibraryModule> {
    return {
      ngModule: CsoftLibraryModule,
      providers: [
        {
          provide: 'env',
          useValue: environment,
        },
      ],
    };
  }
}
