import { Inject, Injectable, OnDestroy } from '@angular/core';
import { UserService } from './user.service';
import { LivelliService } from './livelli.service';
import { TrackingService } from './tracking.service';
import { HelpService } from './help.service';
import { NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { Environment } from 'csoft-library/modelli';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MasterService implements OnDestroy {
  showloading = false;
  isAr = false;
  isDg = false;
  currLevel: any = {};
  destroy$ = new Subject<void>();

  hasLoadedUser: boolean = false;

  constructor(
    private UserSvc: UserService,
    public LevelsSvc: LivelliService,
    private helpSvc: HelpService,
    private routerSvc: Router,
    @Inject('env') private environment: Environment
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  Init() {
    if (this.UserSvc.User) this.GetNews();

    // this.routerSvc.events.pipe(takeUntil(this.destroy$)).subscribe((value) => {
    //   if (
    //     value instanceof NavigationEnd ||
    //     value instanceof NavigationSkipped
    //   ) {
    //     if (this.routerSvc.url === '/login') {
    //       this.showloading = false;
    //     }
    //   }
    // });
  }

  GetNews() {
    if (this.environment.production) {
      this.helpSvc
        .GetNews()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result: any) => {
            if (result.Message) {
              throw new Error(result.Message);
            } else {
              this.helpSvc.totalNews = result.filter((n: any) => {
                return !n.OnlyTicket;
              });
              for (var i = 0; i < this.helpSvc.totalNews.length; i++) {
                if (
                  !(
                    this.helpSvc.totalNews[i].Visualizzati.filter((v: any) => {
                      return v.User_Car_Id == this.UserSvc.User?.ProfileId;
                    }).length > 0
                  )
                ) {
                  this.helpSvc.countHelper = this.helpSvc.countHelper + 1;
                }
              }
            }
          },
          error: (e) => {
            console.log(e);
          },
        });
    }
  }

  isLevelMenuPresent() {
    return this.LevelsSvc.levelsCount > 1;
  }
}
