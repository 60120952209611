import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CategorieService } from './categorie.service';
import { UserService } from './user.service';
import { Environment } from 'csoft-library/modelli';
import { HeaderService } from '../header.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LivelliService {
  levels: any[] = [];
  levelsCount = 0;
  levelOrigin = '';
  levelOpened: any = null;

  constructor(
    private http: HttpClient,
    public CategoriesSvc: CategorieService,
    private UsersSvc: UserService,
    @Inject('env') private environment: Environment,
    private Header: HeaderService
  ) {}

  GetThirdLevels() {
    this.levels = [];
    const url = `${this.environment.ApiDomain}api/tl/GetThirdLevelsByUser?username=${this.UsersSvc.User.UserName}`;
    this.http
      .get(url, { headers: this.Header.getHeader() })
      .subscribe((result: any) => {
        if (result.Message) {
        } else {
          var count = 0;
          result.forEach((item: any) => {
            this.levels.push(item);
            count += 1;
            if (item.Address == this.environment.domain) {
              this.levelOrigin = item.ThirdLevelId;
            }
          });
          this.levelsCount = count;
        }
      });
  }

  GetFirstAvThirdLevel() {
    const url = `${this.environment.ApiDomain}api/tl/GetThirdLevelsByUser?username=${this.UsersSvc.User.UserName}`;
    return this.http
      .get(url, { headers: this.Header.getHeader() })
      .pipe(map((list: any) => list[0]));
  }

  getLevelUrl(level: any) {
    return this.environment.protocol + '//' + level.Address;
  }

  hasOnlyOneLevel() {
    return this.levelsCount == 1;
  }

  openOtherLevelMenu(level: any, justreset: any) {
    this.resetMenu();
    if (!justreset) {
      this.levelOpened = level.ThirdLevelId;
      this.CategoriesSvc.getMenu(level);
    } else {
      this.levelOpened = null;
    }
    // else{
    //   this.levelOrigin
    //   this.CategoriesSvc.getMenu(null)
    // }
  }

  resetMenu() {
    var cats_ = null;
    if (this.levelOpened) {
      cats_ = this.CategoriesSvc.otherMenus.filter((l) => {
        return l.id == this.levelOpened;
      });
      if (cats_.length == 0) {
        this.CategoriesSvc.otherMenus.push({
          id: this.levelOpened,
          cats: this.CategoriesSvc.categsOtherMenu,
        });
        this.CategoriesSvc.categsOtherMenu = [];
      }
    }
    this.levelOpened = null;
  }
}
